/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query GetAllPrMenu {\n    allPrMenu(where: {}, sort: [], limit: 100, offset: 0) {\n      name\n      menuItems {\n        name\n        link\n        submenu {\n          name\n          link\n          newWindow\n        }\n      }\n    }\n  }\n':
    types.GetAllPrMenuDocument,
  '\n  query GetPageFdtvTalent {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "fdtvTalent" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      fdtvTalent {\n        _id\n        name\n        slug {\n          current\n        }\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPageFdtvTalentDocument,
  '\n  query GetPageHomepage {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "homepage" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n    }\n  }\n':
    types.GetPageHomepageDocument,
  '\n  query GetPageLeadership {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "leadership" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      leadership {\n        _id\n        name\n        position\n        slug {\n          current\n        }\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPageLeadershipDocument,
  '\n  query GetPageMediaContacts {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "media-contacts" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      contacts {\n        name\n        email\n      }\n    }\n  }\n':
    types.GetPageMediaContactsDocument,
  '\n  query GetPagePartners {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "partners" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n      pageSections {\n        sectionTitle\n        partnerCarousel {\n          name\n          image {\n            asset {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPagePartnersDocument,
  '\n  query GetPagePressKit {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "press-kit" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      logos {\n        fileName\n        submenu {\n          fileName\n          logo {\n            asset {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPagePressKitDocument,
  '\n  query GetPageProducts {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "products" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n      reel {\n        asset {\n          url\n        }\n      }\n      bodyRaw\n      products {\n        name\n        url\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPageProductsDocument,
  '\n  query GetPrLeaderBySlug($where: PrLeaderFilter = {}) {\n    allPrLeader(where: $where) {\n      name\n      position\n      biographyRaw\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n':
    types.GetPrLeaderBySlugDocument,
  '\n  query GetPrReleaseBySlug($where: PrReleaseFilter = {}) {\n    allPrRelease(where: $where) {\n      _updatedAt\n      datePublished\n      title\n      slug {\n        current\n      }\n      subheading\n      pullQuote\n      bodyRaw\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n':
    types.GetPrReleaseBySlugDocument,
  '\n  query GetPrTalentBySlug($where: PrTalentFilter = {}) {\n    allPrTalent(where: $where) {\n      name\n      biographyRaw\n      xLink\n      igLink\n      ttLink\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n':
    types.GetPrTalentBySlugDocument,
  '\n  query SearchAllPrRelease(\n    $where: PrReleaseFilter = {}\n    $sort: [PrReleaseSorting!] = [{ _updatedAt: DESC }]\n    $limit: Int = 10\n    $offset: Int = 0\n  ) {\n    allPrRelease(where: $where, sort: $sort, limit: $limit, offset: $offset) {\n      _id\n      datePublished\n      subheading\n      title\n      slug {\n        current\n      }\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n':
    types.SearchAllPrReleaseDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAllPrMenu {\n    allPrMenu(where: {}, sort: [], limit: 100, offset: 0) {\n      name\n      menuItems {\n        name\n        link\n        submenu {\n          name\n          link\n          newWindow\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetAllPrMenu {\n    allPrMenu(where: {}, sort: [], limit: 100, offset: 0) {\n      name\n      menuItems {\n        name\n        link\n        submenu {\n          name\n          link\n          newWindow\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPageFdtvTalent {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "fdtvTalent" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      fdtvTalent {\n        _id\n        name\n        slug {\n          current\n        }\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPageFdtvTalent {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "fdtvTalent" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      fdtvTalent {\n        _id\n        name\n        slug {\n          current\n        }\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPageHomepage {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "homepage" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPageHomepage {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "homepage" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPageLeadership {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "leadership" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      leadership {\n        _id\n        name\n        position\n        slug {\n          current\n        }\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPageLeadership {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "leadership" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      leadership {\n        _id\n        name\n        position\n        slug {\n          current\n        }\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPageMediaContacts {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "media-contacts" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      contacts {\n        name\n        email\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPageMediaContacts {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "media-contacts" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      contacts {\n        name\n        email\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPagePartners {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "partners" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n      pageSections {\n        sectionTitle\n        partnerCarousel {\n          name\n          image {\n            asset {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPagePartners {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "partners" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n      pageSections {\n        sectionTitle\n        partnerCarousel {\n          name\n          image {\n            asset {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPagePressKit {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "press-kit" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      logos {\n        fileName\n        submenu {\n          fileName\n          logo {\n            asset {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPagePressKit {\n    allPrPage(\n      where: { _: { is_draft: false }, pageType: { eq: "press-kit" } }\n    ) {\n      banner {\n        asset {\n          url\n        }\n      }\n      logos {\n        fileName\n        submenu {\n          fileName\n          logo {\n            asset {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPageProducts {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "products" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n      reel {\n        asset {\n          url\n        }\n      }\n      bodyRaw\n      products {\n        name\n        url\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPageProducts {\n    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "products" } }) {\n      banner {\n        asset {\n          url\n        }\n      }\n      reel {\n        asset {\n          url\n        }\n      }\n      bodyRaw\n      products {\n        name\n        url\n        image {\n          asset {\n            url\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPrLeaderBySlug($where: PrLeaderFilter = {}) {\n    allPrLeader(where: $where) {\n      name\n      position\n      biographyRaw\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPrLeaderBySlug($where: PrLeaderFilter = {}) {\n    allPrLeader(where: $where) {\n      name\n      position\n      biographyRaw\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPrReleaseBySlug($where: PrReleaseFilter = {}) {\n    allPrRelease(where: $where) {\n      _updatedAt\n      datePublished\n      title\n      slug {\n        current\n      }\n      subheading\n      pullQuote\n      bodyRaw\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPrReleaseBySlug($where: PrReleaseFilter = {}) {\n    allPrRelease(where: $where) {\n      _updatedAt\n      datePublished\n      title\n      slug {\n        current\n      }\n      subheading\n      pullQuote\n      bodyRaw\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPrTalentBySlug($where: PrTalentFilter = {}) {\n    allPrTalent(where: $where) {\n      name\n      biographyRaw\n      xLink\n      igLink\n      ttLink\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPrTalentBySlug($where: PrTalentFilter = {}) {\n    allPrTalent(where: $where) {\n      name\n      biographyRaw\n      xLink\n      igLink\n      ttLink\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchAllPrRelease(\n    $where: PrReleaseFilter = {}\n    $sort: [PrReleaseSorting!] = [{ _updatedAt: DESC }]\n    $limit: Int = 10\n    $offset: Int = 0\n  ) {\n    allPrRelease(where: $where, sort: $sort, limit: $limit, offset: $offset) {\n      _id\n      datePublished\n      subheading\n      title\n      slug {\n        current\n      }\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query SearchAllPrRelease(\n    $where: PrReleaseFilter = {}\n    $sort: [PrReleaseSorting!] = [{ _updatedAt: DESC }]\n    $limit: Int = 10\n    $offset: Int = 0\n  ) {\n    allPrRelease(where: $where, sort: $sort, limit: $limit, offset: $offset) {\n      _id\n      datePublished\n      subheading\n      title\n      slug {\n        current\n      }\n      image {\n        asset {\n          url\n        }\n      }\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
