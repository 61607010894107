import { graphql } from '../gql';

const getPageLeadership = graphql(`
  query GetPageLeadership {
    allPrPage(
      where: { _: { is_draft: false }, pageType: { eq: "leadership" } }
    ) {
      banner {
        asset {
          url
        }
      }
      leadership {
        _id
        name
        position
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
      }
    }
  }
`);

export default getPageLeadership;
