/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type Block = {
  __typename?: 'Block';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Array<Maybe<Span>>>;
  level?: Maybe<Scalars['Float']['output']>;
  listItem?: Maybe<Scalars['String']['output']>;
  style?: Maybe<Scalars['String']['output']>;
};

export type BooleanFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is defined. */
  is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrossDatasetReference = {
  __typename?: 'CrossDatasetReference';
  _dataset?: Maybe<Scalars['String']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  _projectId?: Maybe<Scalars['String']['output']>;
  _ref?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  _weak?: Maybe<Scalars['Boolean']['output']>;
};

export type CrossDatasetReferenceFilter = {
  _dataset?: InputMaybe<StringFilter>;
  _key?: InputMaybe<StringFilter>;
  _projectId?: InputMaybe<StringFilter>;
  _ref?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _weak?: InputMaybe<BooleanFilter>;
};

export type CrossDatasetReferenceSorting = {
  _dataset?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _projectId?: InputMaybe<SortOrder>;
  _ref?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _weak?: InputMaybe<SortOrder>;
};

export type DateFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is defined. */
  is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['Date']['input']>;
};

export type DatetimeFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is defined. */
  is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** A Sanity document */
export type Document = {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DocumentFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
};

export type DocumentSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
};

export type File = {
  __typename?: 'File';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<SanityFileAsset>;
};

export type FileFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  asset?: InputMaybe<SanityFileAssetFilter>;
};

export type FileSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
};

export type FloatFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is defined. */
  is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['Float']['input']>;
};

export type Geopoint = {
  __typename?: 'Geopoint';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  alt?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type GeopointFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  alt?: InputMaybe<FloatFilter>;
  lat?: InputMaybe<FloatFilter>;
  lng?: InputMaybe<FloatFilter>;
};

export type GeopointSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  alt?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  lng?: InputMaybe<SortOrder>;
};

export type HighlightColor = {
  __typename?: 'HighlightColor';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type HighlightColorFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type HighlightColorSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type IdFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars['ID']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['ID']['input']>;
  nin?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Image = {
  __typename?: 'Image';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<SanityImageAsset>;
  crop?: Maybe<SanityImageCrop>;
  hotspot?: Maybe<SanityImageHotspot>;
};

export type ImageFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  asset?: InputMaybe<SanityImageAssetFilter>;
  crop?: InputMaybe<SanityImageCropFilter>;
  hotspot?: InputMaybe<SanityImageHotspotFilter>;
};

export type ImageSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  crop?: InputMaybe<SanityImageCropSorting>;
  hotspot?: InputMaybe<SanityImageHotspotSorting>;
};

export type IntFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is defined. */
  is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['Int']['input']>;
};

export type Link = {
  __typename?: 'Link';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  blank?: Maybe<Scalars['Boolean']['output']>;
  href?: Maybe<Scalars['String']['output']>;
  linkType?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LinkFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  blank?: InputMaybe<BooleanFilter>;
  href?: InputMaybe<StringFilter>;
  linkType?: InputMaybe<FloatFilter>;
  url?: InputMaybe<StringFilter>;
};

export type LinkSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  blank?: InputMaybe<SortOrder>;
  href?: InputMaybe<SortOrder>;
  linkType?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type LinkToRolePage = {
  __typename?: 'LinkToRolePage';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  button?: Maybe<UkButton>;
};

export type LinkToRolePageFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  button?: InputMaybe<UkButtonFilter>;
};

export type LinkToRolePageSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
};

export type MediaTag = Document & {
  __typename?: 'MediaTag';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Slug>;
};

export type MediaTagFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  name?: InputMaybe<SlugFilter>;
};

export type MediaTagSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SlugSorting>;
};

export type Menu = Document & {
  __typename?: 'Menu';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  menuItems?: Maybe<Array<Maybe<MenuItem>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MenuFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  name?: InputMaybe<StringFilter>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  newWindow?: Maybe<Scalars['Boolean']['output']>;
  submenu?: Maybe<Array<Maybe<MenuItem>>>;
};

export type MenuItemFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  link?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  newWindow?: InputMaybe<BooleanFilter>;
};

export type MenuItemSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  newWindow?: InputMaybe<SortOrder>;
};

export type MenuSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type MkActionCarousel = {
  __typename?: 'MkActionCarousel';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  mk_backgroundColor?: Maybe<SimplerColor>;
  mk_backgroundImage?: Maybe<Image>;
  mk_cards?: Maybe<Array<Maybe<MkActionCarouselCard>>>;
  mk_headingh1?: Maybe<Scalars['String']['output']>;
  mk_headingh1Color?: Maybe<SimplerColor>;
  mk_lineUnderHeaderColor?: Maybe<SimplerColor>;
  mk_nextImage?: Maybe<Image>;
};

export type MkActionCarouselCard = {
  __typename?: 'MkActionCarouselCard';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  bodyText?: Maybe<Scalars['String']['output']>;
  bodyTextColor?: Maybe<SimplerColor>;
  mk_backTextColor?: Maybe<SimplerColor>;
  mk_headingText?: Maybe<Scalars['String']['output']>;
  mk_headingTextColor?: Maybe<SimplerColor>;
  mk_sideImage?: Maybe<Image>;
  /** If filled out, overrides header and body text */
  mk_topHtml?: Maybe<Scalars['String']['output']>;
};

export type MkActionCarouselCardFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  bodyText?: InputMaybe<StringFilter>;
  bodyTextColor?: InputMaybe<SimplerColorFilter>;
  mk_backTextColor?: InputMaybe<SimplerColorFilter>;
  mk_headingText?: InputMaybe<StringFilter>;
  mk_headingTextColor?: InputMaybe<SimplerColorFilter>;
  mk_sideImage?: InputMaybe<ImageFilter>;
  mk_topHtml?: InputMaybe<StringFilter>;
};

export type MkActionCarouselCardSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  bodyText?: InputMaybe<SortOrder>;
  bodyTextColor?: InputMaybe<SimplerColorSorting>;
  mk_backTextColor?: InputMaybe<SimplerColorSorting>;
  mk_headingText?: InputMaybe<SortOrder>;
  mk_headingTextColor?: InputMaybe<SimplerColorSorting>;
  mk_sideImage?: InputMaybe<ImageSorting>;
  mk_topHtml?: InputMaybe<SortOrder>;
};

export type MkActionCarouselFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  mk_backgroundColor?: InputMaybe<SimplerColorFilter>;
  mk_backgroundImage?: InputMaybe<ImageFilter>;
  mk_headingh1?: InputMaybe<StringFilter>;
  mk_headingh1Color?: InputMaybe<SimplerColorFilter>;
  mk_lineUnderHeaderColor?: InputMaybe<SimplerColorFilter>;
  mk_nextImage?: InputMaybe<ImageFilter>;
};

export type MkActionCarouselOrMkCallToActionOrMkCustomHtmlTemplateOrMkCustomJsTemplateOrMkHeroOrMkInfoCardsTemplate =

    | MkActionCarousel
    | MkCallToAction
    | MkCustomHtmlTemplate
    | MkCustomJsTemplate
    | MkHero
    | MkInfoCardsTemplate;

export type MkActionCarouselSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  mk_backgroundColor?: InputMaybe<SimplerColorSorting>;
  mk_backgroundImage?: InputMaybe<ImageSorting>;
  mk_headingh1?: InputMaybe<SortOrder>;
  mk_headingh1Color?: InputMaybe<SimplerColorSorting>;
  mk_lineUnderHeaderColor?: InputMaybe<SimplerColorSorting>;
  mk_nextImage?: InputMaybe<ImageSorting>;
};

export type MkCallToAction = {
  __typename?: 'MkCallToAction';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  mk_backgroundColor?: Maybe<SimplerColor>;
  mk_backgroundImage?: Maybe<Image>;
  mk_callToAction?: Maybe<Scalars['String']['output']>;
  mk_callToActionLink?: Maybe<Scalars['String']['output']>;
  mk_cards?: Maybe<Array<Maybe<MkCallToActionCard>>>;
  mk_headingh1?: Maybe<Scalars['String']['output']>;
  mk_headingh1Color?: Maybe<SimplerColor>;
  mk_lineUnderHeaderColor?: Maybe<SimplerColor>;
  mk_topHtml?: Maybe<Scalars['String']['output']>;
  separatorImage?: Maybe<Image>;
};

export type MkCallToActionCard = {
  __typename?: 'MkCallToActionCard';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  bodyText?: Maybe<Scalars['String']['output']>;
  bodyTextColor?: Maybe<SimplerColor>;
  mk_backTextColor?: Maybe<SimplerColor>;
  mk_headingText?: Maybe<Scalars['String']['output']>;
  mk_headingTextColor?: Maybe<SimplerColor>;
  mk_sideImage?: Maybe<Image>;
  /** If filled out, overrides header and body text */
  mk_topHtml?: Maybe<Scalars['String']['output']>;
};

export type MkCallToActionCardFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  bodyText?: InputMaybe<StringFilter>;
  bodyTextColor?: InputMaybe<SimplerColorFilter>;
  mk_backTextColor?: InputMaybe<SimplerColorFilter>;
  mk_headingText?: InputMaybe<StringFilter>;
  mk_headingTextColor?: InputMaybe<SimplerColorFilter>;
  mk_sideImage?: InputMaybe<ImageFilter>;
  mk_topHtml?: InputMaybe<StringFilter>;
};

export type MkCallToActionCardSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  bodyText?: InputMaybe<SortOrder>;
  bodyTextColor?: InputMaybe<SimplerColorSorting>;
  mk_backTextColor?: InputMaybe<SimplerColorSorting>;
  mk_headingText?: InputMaybe<SortOrder>;
  mk_headingTextColor?: InputMaybe<SimplerColorSorting>;
  mk_sideImage?: InputMaybe<ImageSorting>;
  mk_topHtml?: InputMaybe<SortOrder>;
};

export type MkCallToActionFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  mk_backgroundColor?: InputMaybe<SimplerColorFilter>;
  mk_backgroundImage?: InputMaybe<ImageFilter>;
  mk_callToAction?: InputMaybe<StringFilter>;
  mk_callToActionLink?: InputMaybe<StringFilter>;
  mk_headingh1?: InputMaybe<StringFilter>;
  mk_headingh1Color?: InputMaybe<SimplerColorFilter>;
  mk_lineUnderHeaderColor?: InputMaybe<SimplerColorFilter>;
  mk_topHtml?: InputMaybe<StringFilter>;
  separatorImage?: InputMaybe<ImageFilter>;
};

export type MkCallToActionSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  mk_backgroundColor?: InputMaybe<SimplerColorSorting>;
  mk_backgroundImage?: InputMaybe<ImageSorting>;
  mk_callToAction?: InputMaybe<SortOrder>;
  mk_callToActionLink?: InputMaybe<SortOrder>;
  mk_headingh1?: InputMaybe<SortOrder>;
  mk_headingh1Color?: InputMaybe<SimplerColorSorting>;
  mk_lineUnderHeaderColor?: InputMaybe<SimplerColorSorting>;
  mk_topHtml?: InputMaybe<SortOrder>;
  separatorImage?: InputMaybe<ImageSorting>;
};

export type MkCustomHtmlTemplate = {
  __typename?: 'MkCustomHtmlTemplate';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  /** All JS will be removed. Must go into Custom JS Component  */
  mk_html?: Maybe<Scalars['String']['output']>;
  mk_title?: Maybe<Scalars['String']['output']>;
};

export type MkCustomHtmlTemplateFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  mk_html?: InputMaybe<StringFilter>;
  mk_title?: InputMaybe<StringFilter>;
};

export type MkCustomHtmlTemplateSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  mk_html?: InputMaybe<SortOrder>;
  mk_title?: InputMaybe<SortOrder>;
};

export type MkCustomJsTemplate = {
  __typename?: 'MkCustomJsTemplate';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  mk_js?: Maybe<Scalars['String']['output']>;
  mk_title?: Maybe<Scalars['String']['output']>;
};

export type MkCustomJsTemplateFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  mk_js?: InputMaybe<StringFilter>;
  mk_title?: InputMaybe<StringFilter>;
};

export type MkCustomJsTemplateSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  mk_js?: InputMaybe<SortOrder>;
  mk_title?: InputMaybe<SortOrder>;
};

export type MkHero = {
  __typename?: 'MkHero';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  mk_backgroundImage?: Maybe<Image>;
  mk_bottomText?: Maybe<Scalars['String']['output']>;
  mk_callToAction?: Maybe<Scalars['String']['output']>;
  mk_callToActionLink?: Maybe<Scalars['String']['output']>;
  mk_frontImage?: Maybe<Image>;
  mk_headingh1?: Maybe<Scalars['String']['output']>;
  mk_headingh2?: Maybe<Scalars['String']['output']>;
  mk_tagline?: Maybe<Scalars['String']['output']>;
  mk_topHtml?: Maybe<Scalars['String']['output']>;
};

export type MkHeroFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  mk_backgroundImage?: InputMaybe<ImageFilter>;
  mk_bottomText?: InputMaybe<StringFilter>;
  mk_callToAction?: InputMaybe<StringFilter>;
  mk_callToActionLink?: InputMaybe<StringFilter>;
  mk_frontImage?: InputMaybe<ImageFilter>;
  mk_headingh1?: InputMaybe<StringFilter>;
  mk_headingh2?: InputMaybe<StringFilter>;
  mk_tagline?: InputMaybe<StringFilter>;
  mk_topHtml?: InputMaybe<StringFilter>;
};

export type MkHeroSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  mk_backgroundImage?: InputMaybe<ImageSorting>;
  mk_bottomText?: InputMaybe<SortOrder>;
  mk_callToAction?: InputMaybe<SortOrder>;
  mk_callToActionLink?: InputMaybe<SortOrder>;
  mk_frontImage?: InputMaybe<ImageSorting>;
  mk_headingh1?: InputMaybe<SortOrder>;
  mk_headingh2?: InputMaybe<SortOrder>;
  mk_tagline?: InputMaybe<SortOrder>;
  mk_topHtml?: InputMaybe<SortOrder>;
};

export type MkHomePage = Document & {
  __typename?: 'MkHomePage';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  mk_footer?: Maybe<Scalars['Boolean']['output']>;
  mk_header?: Maybe<Scalars['String']['output']>;
  mk_pageBuilder?: Maybe<
    Array<
      Maybe<MkActionCarouselOrMkCallToActionOrMkCustomHtmlTemplateOrMkCustomJsTemplateOrMkHeroOrMkInfoCardsTemplate>
    >
  >;
  mk_seo?: Maybe<Seo>;
  mk_title?: Maybe<Scalars['String']['output']>;
};

export type MkHomePageFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  mk_footer?: InputMaybe<BooleanFilter>;
  mk_header?: InputMaybe<StringFilter>;
  mk_seo?: InputMaybe<SeoFilter>;
  mk_title?: InputMaybe<StringFilter>;
};

export type MkHomePageSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  mk_footer?: InputMaybe<SortOrder>;
  mk_header?: InputMaybe<SortOrder>;
  mk_seo?: InputMaybe<SeoSorting>;
  mk_title?: InputMaybe<SortOrder>;
};

export type MkInfoCard = {
  __typename?: 'MkInfoCard';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  mk_backTextColor?: Maybe<SimplerColor>;
  mk_flipDirection?: Maybe<Scalars['String']['output']>;
  mk_flipSpeedBackToFront?: Maybe<Scalars['Float']['output']>;
  mk_flipSpeedFrontToBack?: Maybe<Scalars['Float']['output']>;
  mk_frontCardColor?: Maybe<SimplerColor>;
  mk_frontImage?: Maybe<Image>;
  mk_headingFront?: Maybe<Scalars['String']['output']>;
  mk_textBackOfCards?: Maybe<Scalars['String']['output']>;
};

export type MkInfoCardFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  mk_backTextColor?: InputMaybe<SimplerColorFilter>;
  mk_flipDirection?: InputMaybe<StringFilter>;
  mk_flipSpeedBackToFront?: InputMaybe<FloatFilter>;
  mk_flipSpeedFrontToBack?: InputMaybe<FloatFilter>;
  mk_frontCardColor?: InputMaybe<SimplerColorFilter>;
  mk_frontImage?: InputMaybe<ImageFilter>;
  mk_headingFront?: InputMaybe<StringFilter>;
  mk_textBackOfCards?: InputMaybe<StringFilter>;
};

export type MkInfoCardSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  mk_backTextColor?: InputMaybe<SimplerColorSorting>;
  mk_flipDirection?: InputMaybe<SortOrder>;
  mk_flipSpeedBackToFront?: InputMaybe<SortOrder>;
  mk_flipSpeedFrontToBack?: InputMaybe<SortOrder>;
  mk_frontCardColor?: InputMaybe<SimplerColorSorting>;
  mk_frontImage?: InputMaybe<ImageSorting>;
  mk_headingFront?: InputMaybe<SortOrder>;
  mk_textBackOfCards?: InputMaybe<SortOrder>;
};

export type MkInfoCardsTemplate = {
  __typename?: 'MkInfoCardsTemplate';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  mk_backgroundImage?: Maybe<Image>;
  mk_cards?: Maybe<Array<Maybe<MkInfoCard>>>;
  mk_headingh1?: Maybe<Scalars['String']['output']>;
  mk_headingh2?: Maybe<Scalars['String']['output']>;
  mk_topHtml?: Maybe<Scalars['String']['output']>;
};

export type MkInfoCardsTemplateFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  mk_backgroundImage?: InputMaybe<ImageFilter>;
  mk_headingh1?: InputMaybe<StringFilter>;
  mk_headingh2?: InputMaybe<StringFilter>;
  mk_topHtml?: InputMaybe<StringFilter>;
};

export type MkInfoCardsTemplateSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  mk_backgroundImage?: InputMaybe<ImageSorting>;
  mk_headingh1?: InputMaybe<SortOrder>;
  mk_headingh2?: InputMaybe<SortOrder>;
  mk_topHtml?: InputMaybe<SortOrder>;
};

export type MkMarketingPage = Document & {
  __typename?: 'MkMarketingPage';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  mk_footer?: Maybe<Scalars['Boolean']['output']>;
  mk_header?: Maybe<Scalars['String']['output']>;
  mk_pageBuilder?: Maybe<
    Array<
      Maybe<MkActionCarouselOrMkCallToActionOrMkCustomHtmlTemplateOrMkCustomJsTemplateOrMkHeroOrMkInfoCardsTemplate>
    >
  >;
  mk_seo?: Maybe<Seo>;
  mk_slug?: Maybe<Slug>;
  mk_title?: Maybe<Scalars['String']['output']>;
};

export type MkMarketingPageFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  mk_footer?: InputMaybe<BooleanFilter>;
  mk_header?: InputMaybe<StringFilter>;
  mk_seo?: InputMaybe<SeoFilter>;
  mk_slug?: InputMaybe<SlugFilter>;
  mk_title?: InputMaybe<StringFilter>;
};

export type MkMarketingPageSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  mk_footer?: InputMaybe<SortOrder>;
  mk_header?: InputMaybe<SortOrder>;
  mk_seo?: InputMaybe<SeoSorting>;
  mk_slug?: InputMaybe<SlugSorting>;
  mk_title?: InputMaybe<SortOrder>;
};

export type MkRedirect = Document & {
  __typename?: 'MkRedirect';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  mk_base_path?: Maybe<Scalars['Boolean']['output']>;
  mk_enabled?: Maybe<Scalars['Boolean']['output']>;
  mk_new_path?: Maybe<Scalars['String']['output']>;
  mk_old_path?: Maybe<Scalars['String']['output']>;
  mk_permanent?: Maybe<Scalars['Boolean']['output']>;
};

export type MkRedirectFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  mk_base_path?: InputMaybe<BooleanFilter>;
  mk_enabled?: InputMaybe<BooleanFilter>;
  mk_new_path?: InputMaybe<StringFilter>;
  mk_old_path?: InputMaybe<StringFilter>;
  mk_permanent?: InputMaybe<BooleanFilter>;
};

export type MkRedirectSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  mk_base_path?: InputMaybe<SortOrder>;
  mk_enabled?: InputMaybe<SortOrder>;
  mk_new_path?: InputMaybe<SortOrder>;
  mk_old_path?: InputMaybe<SortOrder>;
  mk_permanent?: InputMaybe<SortOrder>;
};

export type MkRewrite = Document & {
  __typename?: 'MkRewrite';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  mk_enabled?: Maybe<Scalars['Boolean']['output']>;
  mk_new_path?: Maybe<Scalars['String']['output']>;
  mk_old_path?: Maybe<Scalars['String']['output']>;
};

export type MkRewriteFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  mk_enabled?: InputMaybe<BooleanFilter>;
  mk_new_path?: InputMaybe<StringFilter>;
  mk_old_path?: InputMaybe<StringFilter>;
};

export type MkRewriteSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  mk_enabled?: InputMaybe<SortOrder>;
  mk_new_path?: InputMaybe<SortOrder>;
  mk_old_path?: InputMaybe<SortOrder>;
};

export type OpenGraph = {
  __typename?: 'OpenGraph';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  title?: Maybe<Scalars['String']['output']>;
  /** 'website', or 'article' etc */
  type?: Maybe<Scalars['String']['output']>;
};

export type OpenGraphFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  image?: InputMaybe<ImageFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
};

export type OpenGraphSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type PrContact = {
  __typename?: 'PrContact';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PrContactFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type PrContactSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type PrLeader = Document & {
  __typename?: 'PrLeader';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  biographyRaw?: Maybe<Scalars['JSON']['output']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Slug>;
};

export type PrLeaderFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<StringFilter>;
  slug?: InputMaybe<SlugFilter>;
};

export type PrLeaderSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SlugSorting>;
};

export type PrLogo = Document & {
  __typename?: 'PrLogo';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Image>;
  logoType?: Maybe<Scalars['String']['output']>;
};

export type PrLogoFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  fileName?: InputMaybe<StringFilter>;
  logo?: InputMaybe<ImageFilter>;
  logoType?: InputMaybe<StringFilter>;
};

export type PrLogoItem = {
  __typename?: 'PrLogoItem';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Image>;
  submenu?: Maybe<Array<Maybe<PrLogoItem>>>;
};

export type PrLogoItemFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  fileName?: InputMaybe<StringFilter>;
  logo?: InputMaybe<ImageFilter>;
};

export type PrLogoItemSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  logo?: InputMaybe<ImageSorting>;
};

export type PrLogoSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  logo?: InputMaybe<ImageSorting>;
  logoType?: InputMaybe<SortOrder>;
};

export type PrMenu = Document & {
  __typename?: 'PrMenu';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  menuItems?: Maybe<Array<Maybe<PrMenuItem>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PrMenuFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  name?: InputMaybe<StringFilter>;
};

export type PrMenuItem = {
  __typename?: 'PrMenuItem';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  newWindow?: Maybe<Scalars['Boolean']['output']>;
  submenu?: Maybe<Array<Maybe<PrMenuItem>>>;
};

export type PrMenuItemFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  link?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  newWindow?: InputMaybe<BooleanFilter>;
};

export type PrMenuItemSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  newWindow?: InputMaybe<SortOrder>;
};

export type PrMenuSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type PrPage = Document & {
  __typename?: 'PrPage';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  banner?: Maybe<Image>;
  bodyRaw?: Maybe<Scalars['JSON']['output']>;
  contacts?: Maybe<Array<Maybe<PrContact>>>;
  fdtvTalent?: Maybe<Array<Maybe<PrTalent>>>;
  leadership?: Maybe<Array<Maybe<PrLeader>>>;
  logos?: Maybe<Array<Maybe<PrLogoItem>>>;
  pageSections?: Maybe<Array<Maybe<PrPageSection>>>;
  pageType?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Maybe<PrProduct>>>;
  reel?: Maybe<File>;
};

export type PrPageFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  banner?: InputMaybe<ImageFilter>;
  pageType?: InputMaybe<StringFilter>;
  reel?: InputMaybe<FileFilter>;
};

export type PrPageSection = Document & {
  __typename?: 'PrPageSection';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  partnerCarousel?: Maybe<Array<Maybe<PrPartner>>>;
  sectionTitle?: Maybe<Scalars['String']['output']>;
  sectionType?: Maybe<Scalars['String']['output']>;
};

export type PrPageSectionFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  sectionTitle?: InputMaybe<StringFilter>;
  sectionType?: InputMaybe<StringFilter>;
};

export type PrPageSectionSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  sectionTitle?: InputMaybe<SortOrder>;
  sectionType?: InputMaybe<SortOrder>;
};

export type PrPageSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  banner?: InputMaybe<ImageSorting>;
  pageType?: InputMaybe<SortOrder>;
  reel?: InputMaybe<FileSorting>;
};

export type PrPartner = Document & {
  __typename?: 'PrPartner';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PrPartnerFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
};

export type PrPartnerSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
};

export type PrProduct = Document & {
  __typename?: 'PrProduct';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PrProductFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type PrProductSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type PrRelease = Document & {
  __typename?: 'PrRelease';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  bodyRaw?: Maybe<Scalars['JSON']['output']>;
  datePublished?: Maybe<Scalars['Date']['output']>;
  image?: Maybe<Image>;
  /** Optional: Insert a stylized pull-quote in the press release layout */
  pullQuote?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Slug>;
  /** Optional: Insert a subheading below the main press release headline */
  subheading?: Maybe<Scalars['String']['output']>;
  /** Maximum 300 characters */
  title?: Maybe<Scalars['String']['output']>;
};

export type PrReleaseFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  datePublished?: InputMaybe<DateFilter>;
  image?: InputMaybe<ImageFilter>;
  pullQuote?: InputMaybe<StringFilter>;
  slug?: InputMaybe<SlugFilter>;
  subheading?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type PrReleaseSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  datePublished?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  pullQuote?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SlugSorting>;
  subheading?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type PrTalent = Document & {
  __typename?: 'PrTalent';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  biographyRaw?: Maybe<Scalars['JSON']['output']>;
  igLink?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Slug>;
  ttLink?: Maybe<Scalars['String']['output']>;
  xLink?: Maybe<Scalars['String']['output']>;
};

export type PrTalentFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  igLink?: InputMaybe<StringFilter>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<SlugFilter>;
  ttLink?: InputMaybe<StringFilter>;
  xLink?: InputMaybe<StringFilter>;
};

export type PrTalentSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  igLink?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SlugSorting>;
  ttLink?: InputMaybe<SortOrder>;
  xLink?: InputMaybe<SortOrder>;
};

export type Query = {
  __typename?: 'Query';
  Document?: Maybe<Document>;
  MediaTag?: Maybe<MediaTag>;
  Menu?: Maybe<Menu>;
  MkHomePage?: Maybe<MkHomePage>;
  MkMarketingPage?: Maybe<MkMarketingPage>;
  MkRedirect?: Maybe<MkRedirect>;
  MkRewrite?: Maybe<MkRewrite>;
  PrLeader?: Maybe<PrLeader>;
  PrLogo?: Maybe<PrLogo>;
  PrMenu?: Maybe<PrMenu>;
  PrPage?: Maybe<PrPage>;
  PrPageSection?: Maybe<PrPageSection>;
  PrPartner?: Maybe<PrPartner>;
  PrProduct?: Maybe<PrProduct>;
  PrRelease?: Maybe<PrRelease>;
  PrTalent?: Maybe<PrTalent>;
  SanityFileAsset?: Maybe<SanityFileAsset>;
  SanityImageAsset?: Maybe<SanityImageAsset>;
  Settings?: Maybe<Settings>;
  UkButton?: Maybe<UkButton>;
  UkCareerProgressionLevel?: Maybe<UkCareerProgressionLevel>;
  UkEmployeeBenefit?: Maybe<UkEmployeeBenefit>;
  UkEmployeeProfile?: Maybe<UkEmployeeProfile>;
  UkEmployeeTestimonial?: Maybe<UkEmployeeTestimonial>;
  UkFdprinciple?: Maybe<UkFdprinciple>;
  UkLocationIcon?: Maybe<UkLocationIcon>;
  UkMenu?: Maybe<UkMenu>;
  UkPage?: Maybe<UkPage>;
  UkPageSection?: Maybe<UkPageSection>;
  UkProduct?: Maybe<UkProduct>;
  UkRoleOverview?: Maybe<UkRoleOverview>;
  allDocument: Array<Document>;
  allMediaTag: Array<MediaTag>;
  allMenu: Array<Menu>;
  allMkHomePage: Array<MkHomePage>;
  allMkMarketingPage: Array<MkMarketingPage>;
  allMkRedirect: Array<MkRedirect>;
  allMkRewrite: Array<MkRewrite>;
  allPrLeader: Array<PrLeader>;
  allPrLogo: Array<PrLogo>;
  allPrMenu: Array<PrMenu>;
  allPrPage: Array<PrPage>;
  allPrPageSection: Array<PrPageSection>;
  allPrPartner: Array<PrPartner>;
  allPrProduct: Array<PrProduct>;
  allPrRelease: Array<PrRelease>;
  allPrTalent: Array<PrTalent>;
  allSanityFileAsset: Array<SanityFileAsset>;
  allSanityImageAsset: Array<SanityImageAsset>;
  allSettings: Array<Settings>;
  allUkButton: Array<UkButton>;
  allUkCareerProgressionLevel: Array<UkCareerProgressionLevel>;
  allUkEmployeeBenefit: Array<UkEmployeeBenefit>;
  allUkEmployeeProfile: Array<UkEmployeeProfile>;
  allUkEmployeeTestimonial: Array<UkEmployeeTestimonial>;
  allUkFdprinciple: Array<UkFdprinciple>;
  allUkLocationIcon: Array<UkLocationIcon>;
  allUkMenu: Array<UkMenu>;
  allUkPage: Array<UkPage>;
  allUkPageSection: Array<UkPageSection>;
  allUkProduct: Array<UkProduct>;
  allUkRoleOverview: Array<UkRoleOverview>;
};

export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMediaTagArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMenuArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMkHomePageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMkMarketingPageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMkRedirectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMkRewriteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrLeaderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrLogoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrMenuArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrPageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrPageSectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrPartnerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrReleaseArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPrTalentArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySanityFileAssetArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySanityImageAssetArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySettingsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkButtonArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkCareerProgressionLevelArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkEmployeeBenefitArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkEmployeeProfileArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkEmployeeTestimonialArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkFdprincipleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkLocationIconArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkMenuArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkPageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkPageSectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUkRoleOverviewArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAllDocumentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DocumentSorting>>;
  where?: InputMaybe<DocumentFilter>;
};

export type QueryAllMediaTagArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MediaTagSorting>>;
  where?: InputMaybe<MediaTagFilter>;
};

export type QueryAllMenuArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MenuSorting>>;
  where?: InputMaybe<MenuFilter>;
};

export type QueryAllMkHomePageArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MkHomePageSorting>>;
  where?: InputMaybe<MkHomePageFilter>;
};

export type QueryAllMkMarketingPageArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MkMarketingPageSorting>>;
  where?: InputMaybe<MkMarketingPageFilter>;
};

export type QueryAllMkRedirectArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MkRedirectSorting>>;
  where?: InputMaybe<MkRedirectFilter>;
};

export type QueryAllMkRewriteArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MkRewriteSorting>>;
  where?: InputMaybe<MkRewriteFilter>;
};

export type QueryAllPrLeaderArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrLeaderSorting>>;
  where?: InputMaybe<PrLeaderFilter>;
};

export type QueryAllPrLogoArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrLogoSorting>>;
  where?: InputMaybe<PrLogoFilter>;
};

export type QueryAllPrMenuArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrMenuSorting>>;
  where?: InputMaybe<PrMenuFilter>;
};

export type QueryAllPrPageArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrPageSorting>>;
  where?: InputMaybe<PrPageFilter>;
};

export type QueryAllPrPageSectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrPageSectionSorting>>;
  where?: InputMaybe<PrPageSectionFilter>;
};

export type QueryAllPrPartnerArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrPartnerSorting>>;
  where?: InputMaybe<PrPartnerFilter>;
};

export type QueryAllPrProductArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrProductSorting>>;
  where?: InputMaybe<PrProductFilter>;
};

export type QueryAllPrReleaseArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrReleaseSorting>>;
  where?: InputMaybe<PrReleaseFilter>;
};

export type QueryAllPrTalentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PrTalentSorting>>;
  where?: InputMaybe<PrTalentFilter>;
};

export type QueryAllSanityFileAssetArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SanityFileAssetSorting>>;
  where?: InputMaybe<SanityFileAssetFilter>;
};

export type QueryAllSanityImageAssetArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SanityImageAssetSorting>>;
  where?: InputMaybe<SanityImageAssetFilter>;
};

export type QueryAllSettingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SettingsSorting>>;
  where?: InputMaybe<SettingsFilter>;
};

export type QueryAllUkButtonArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkButtonSorting>>;
  where?: InputMaybe<UkButtonFilter>;
};

export type QueryAllUkCareerProgressionLevelArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkCareerProgressionLevelSorting>>;
  where?: InputMaybe<UkCareerProgressionLevelFilter>;
};

export type QueryAllUkEmployeeBenefitArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkEmployeeBenefitSorting>>;
  where?: InputMaybe<UkEmployeeBenefitFilter>;
};

export type QueryAllUkEmployeeProfileArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkEmployeeProfileSorting>>;
  where?: InputMaybe<UkEmployeeProfileFilter>;
};

export type QueryAllUkEmployeeTestimonialArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkEmployeeTestimonialSorting>>;
  where?: InputMaybe<UkEmployeeTestimonialFilter>;
};

export type QueryAllUkFdprincipleArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkFdprincipleSorting>>;
  where?: InputMaybe<UkFdprincipleFilter>;
};

export type QueryAllUkLocationIconArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkLocationIconSorting>>;
  where?: InputMaybe<UkLocationIconFilter>;
};

export type QueryAllUkMenuArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkMenuSorting>>;
  where?: InputMaybe<UkMenuFilter>;
};

export type QueryAllUkPageArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkPageSorting>>;
  where?: InputMaybe<UkPageFilter>;
};

export type QueryAllUkPageSectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkPageSectionSorting>>;
  where?: InputMaybe<UkPageSectionFilter>;
};

export type QueryAllUkProductArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkProductSorting>>;
  where?: InputMaybe<UkProductFilter>;
};

export type QueryAllUkRoleOverviewArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UkRoleOverviewSorting>>;
  where?: InputMaybe<UkRoleOverviewFilter>;
};

export type SanityAssetSourceData = {
  __typename?: 'SanityAssetSourceData';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  /** The unique ID for the asset within the originating source so you can programatically find back to it */
  id?: Maybe<Scalars['String']['output']>;
  /** A canonical name for the source this asset is originating from */
  name?: Maybe<Scalars['String']['output']>;
  /** A URL to find more information about this asset in the originating source */
  url?: Maybe<Scalars['String']['output']>;
};

export type SanityAssetSourceDataFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityAssetSourceDataSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityFileAsset = Document & {
  __typename?: 'SanityFileAsset';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  altText?: Maybe<Scalars['String']['output']>;
  assetId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  sha1hash?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<SanityAssetSourceData>;
  title?: Maybe<Scalars['String']['output']>;
  uploadId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SanityFileAssetFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  altText?: InputMaybe<StringFilter>;
  assetId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  extension?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  mimeType?: InputMaybe<StringFilter>;
  originalFilename?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  sha1hash?: InputMaybe<StringFilter>;
  size?: InputMaybe<FloatFilter>;
  source?: InputMaybe<SanityAssetSourceDataFilter>;
  title?: InputMaybe<StringFilter>;
  uploadId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityFileAssetSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  altText?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  extension?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  originalFilename?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  sha1hash?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  source?: InputMaybe<SanityAssetSourceDataSorting>;
  title?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityImageAsset = Document & {
  __typename?: 'SanityImageAsset';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  altText?: Maybe<Scalars['String']['output']>;
  assetId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<SanityImageMetadata>;
  mimeType?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  sha1hash?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<SanityAssetSourceData>;
  title?: Maybe<Scalars['String']['output']>;
  uploadId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SanityImageAssetFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  altText?: InputMaybe<StringFilter>;
  assetId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  extension?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<SanityImageMetadataFilter>;
  mimeType?: InputMaybe<StringFilter>;
  originalFilename?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  sha1hash?: InputMaybe<StringFilter>;
  size?: InputMaybe<FloatFilter>;
  source?: InputMaybe<SanityAssetSourceDataFilter>;
  title?: InputMaybe<StringFilter>;
  uploadId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityImageAssetSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  altText?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  extension?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SanityImageMetadataSorting>;
  mimeType?: InputMaybe<SortOrder>;
  originalFilename?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  sha1hash?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  source?: InputMaybe<SanityAssetSourceDataSorting>;
  title?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityImageCrop = {
  __typename?: 'SanityImageCrop';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  bottom?: Maybe<Scalars['Float']['output']>;
  left?: Maybe<Scalars['Float']['output']>;
  right?: Maybe<Scalars['Float']['output']>;
  top?: Maybe<Scalars['Float']['output']>;
};

export type SanityImageCropFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  bottom?: InputMaybe<FloatFilter>;
  left?: InputMaybe<FloatFilter>;
  right?: InputMaybe<FloatFilter>;
  top?: InputMaybe<FloatFilter>;
};

export type SanityImageCropSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  bottom?: InputMaybe<SortOrder>;
  left?: InputMaybe<SortOrder>;
  right?: InputMaybe<SortOrder>;
  top?: InputMaybe<SortOrder>;
};

export type SanityImageDimensions = {
  __typename?: 'SanityImageDimensions';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type SanityImageDimensionsFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  aspectRatio?: InputMaybe<FloatFilter>;
  height?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
};

export type SanityImageDimensionsSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  aspectRatio?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type SanityImageHotspot = {
  __typename?: 'SanityImageHotspot';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

export type SanityImageHotspotFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  height?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
  x?: InputMaybe<FloatFilter>;
  y?: InputMaybe<FloatFilter>;
};

export type SanityImageHotspotSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type SanityImageMetadata = {
  __typename?: 'SanityImageMetadata';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  blurHash?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<SanityImageDimensions>;
  hasAlpha?: Maybe<Scalars['Boolean']['output']>;
  isOpaque?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Geopoint>;
  lqip?: Maybe<Scalars['String']['output']>;
  palette?: Maybe<SanityImagePalette>;
};

export type SanityImageMetadataFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  blurHash?: InputMaybe<StringFilter>;
  dimensions?: InputMaybe<SanityImageDimensionsFilter>;
  hasAlpha?: InputMaybe<BooleanFilter>;
  isOpaque?: InputMaybe<BooleanFilter>;
  location?: InputMaybe<GeopointFilter>;
  lqip?: InputMaybe<StringFilter>;
  palette?: InputMaybe<SanityImagePaletteFilter>;
};

export type SanityImageMetadataSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  blurHash?: InputMaybe<SortOrder>;
  dimensions?: InputMaybe<SanityImageDimensionsSorting>;
  hasAlpha?: InputMaybe<SortOrder>;
  isOpaque?: InputMaybe<SortOrder>;
  location?: InputMaybe<GeopointSorting>;
  lqip?: InputMaybe<SortOrder>;
  palette?: InputMaybe<SanityImagePaletteSorting>;
};

export type SanityImagePalette = {
  __typename?: 'SanityImagePalette';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  darkMuted?: Maybe<SanityImagePaletteSwatch>;
  darkVibrant?: Maybe<SanityImagePaletteSwatch>;
  dominant?: Maybe<SanityImagePaletteSwatch>;
  lightMuted?: Maybe<SanityImagePaletteSwatch>;
  lightVibrant?: Maybe<SanityImagePaletteSwatch>;
  muted?: Maybe<SanityImagePaletteSwatch>;
  vibrant?: Maybe<SanityImagePaletteSwatch>;
};

export type SanityImagePaletteFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  darkMuted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  darkVibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  dominant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  lightMuted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  lightVibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  muted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  vibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
};

export type SanityImagePaletteSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  darkMuted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  darkVibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  dominant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  lightMuted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  lightVibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  muted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  vibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
};

export type SanityImagePaletteSwatch = {
  __typename?: 'SanityImagePaletteSwatch';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  foreground?: Maybe<Scalars['String']['output']>;
  population?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SanityImagePaletteSwatchFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  background?: InputMaybe<StringFilter>;
  foreground?: InputMaybe<StringFilter>;
  population?: InputMaybe<FloatFilter>;
  title?: InputMaybe<StringFilter>;
};

export type SanityImagePaletteSwatchSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  background?: InputMaybe<SortOrder>;
  foreground?: InputMaybe<SortOrder>;
  population?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type Sanity_DocumentFilter = {
  /** All documents that are drafts. */
  is_draft?: InputMaybe<Scalars['Boolean']['input']>;
  /** All documents referencing the given document ID. */
  references?: InputMaybe<Scalars['ID']['input']>;
};

export type Seo = {
  __typename?: 'Seo';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  /** Defaults to [basepath]/[slug] */
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  /** Hide this page from search engines and the sitemap */
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  openGraph?: Maybe<OpenGraph>;
  /** Defaults to beginning of the article body */
  seoDescription?: Maybe<Scalars['String']['output']>;
  seoImage?: Maybe<Image>;
  /** comma separated */
  seoKeywords?: Maybe<Scalars['String']['output']>;
  /** comma separated */
  seoSyn?: Maybe<Scalars['String']['output']>;
  /** Defaults to artilce title */
  seoTitle?: Maybe<Scalars['String']['output']>;
};

export type SeoFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  canonicalUrl?: InputMaybe<StringFilter>;
  noIndex?: InputMaybe<BooleanFilter>;
  openGraph?: InputMaybe<OpenGraphFilter>;
  seoDescription?: InputMaybe<StringFilter>;
  seoImage?: InputMaybe<ImageFilter>;
  seoKeywords?: InputMaybe<StringFilter>;
  seoSyn?: InputMaybe<StringFilter>;
  seoTitle?: InputMaybe<StringFilter>;
};

export type SeoSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  canonicalUrl?: InputMaybe<SortOrder>;
  noIndex?: InputMaybe<SortOrder>;
  openGraph?: InputMaybe<OpenGraphSorting>;
  seoDescription?: InputMaybe<SortOrder>;
  seoImage?: InputMaybe<ImageSorting>;
  seoKeywords?: InputMaybe<SortOrder>;
  seoSyn?: InputMaybe<SortOrder>;
  seoTitle?: InputMaybe<SortOrder>;
};

export type Settings = Document & {
  __typename?: 'Settings';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  clientUrlMarketing?: Maybe<Scalars['String']['output']>;
  clientUrlPress?: Maybe<Scalars['String']['output']>;
  clientUrlUk?: Maybe<Scalars['String']['output']>;
};

export type SettingsFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  clientUrlMarketing?: InputMaybe<StringFilter>;
  clientUrlPress?: InputMaybe<StringFilter>;
  clientUrlUk?: InputMaybe<StringFilter>;
};

export type SettingsSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  clientUrlMarketing?: InputMaybe<SortOrder>;
  clientUrlPress?: InputMaybe<SortOrder>;
  clientUrlUk?: InputMaybe<SortOrder>;
};

export type SimplerColor = {
  __typename?: 'SimplerColor';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SimplerColorFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type SimplerColorSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type Slug = {
  __typename?: 'Slug';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  current?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type SlugFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  current?: InputMaybe<StringFilter>;
  source?: InputMaybe<StringFilter>;
};

export type SlugSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  current?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  /** Sorts on the value in ascending order. */
  Asc = 'ASC',
  /** Sorts on the value in descending order. */
  Desc = 'DESC',
}

export type Span = {
  __typename?: 'Span';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  marks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  text?: Maybe<Scalars['String']['output']>;
};

export type StringFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Checks if the value is defined. */
  is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars['String']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TextColor = {
  __typename?: 'TextColor';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TextColorFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TextColorSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type UkButton = Document & {
  __typename?: 'UkButton';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  buttonTitle?: Maybe<Scalars['String']['output']>;
  buttonURL?: Maybe<Scalars['String']['output']>;
};

export type UkButtonFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  buttonTitle?: InputMaybe<StringFilter>;
  buttonURL?: InputMaybe<StringFilter>;
};

export type UkButtonSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  buttonTitle?: InputMaybe<SortOrder>;
  buttonURL?: InputMaybe<SortOrder>;
};

export type UkCareerProgressionLevel = Document & {
  __typename?: 'UkCareerProgressionLevel';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  progressionLevel?: Maybe<Scalars['Float']['output']>;
  progressionTitle?: Maybe<UkRoleOverview>;
};

export type UkCareerProgressionLevelFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  progressionLevel?: InputMaybe<FloatFilter>;
  progressionTitle?: InputMaybe<UkRoleOverviewFilter>;
};

export type UkCareerProgressionLevelSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  progressionLevel?: InputMaybe<SortOrder>;
};

export type UkEmployeeBenefit = Document & {
  __typename?: 'UkEmployeeBenefit';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  benefitName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
};

export type UkEmployeeBenefitFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  benefitName?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  emoji?: InputMaybe<StringFilter>;
};

export type UkEmployeeBenefitSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  benefitName?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  emoji?: InputMaybe<SortOrder>;
};

export type UkEmployeeProfile = Document & {
  __typename?: 'UkEmployeeProfile';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  employeeName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Image>;
};

export type UkEmployeeProfileFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  employeeName?: InputMaybe<StringFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  profilePicture?: InputMaybe<ImageFilter>;
};

export type UkEmployeeProfileSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  employeeName?: InputMaybe<SortOrder>;
  jobTitle?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<ImageSorting>;
};

export type UkEmployeeTestimonial = Document & {
  __typename?: 'UkEmployeeTestimonial';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  employeeName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Image>;
  testimonal?: Maybe<Scalars['String']['output']>;
};

export type UkEmployeeTestimonialFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  employeeName?: InputMaybe<StringFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  profilePicture?: InputMaybe<ImageFilter>;
  testimonal?: InputMaybe<StringFilter>;
};

export type UkEmployeeTestimonialSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  employeeName?: InputMaybe<SortOrder>;
  jobTitle?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<ImageSorting>;
  testimonal?: InputMaybe<SortOrder>;
};

export type UkFdprinciple = Document & {
  __typename?: 'UkFdprinciple';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fdPrinciple?: Maybe<Scalars['String']['output']>;
};

export type UkFdprincipleFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  fdPrinciple?: InputMaybe<StringFilter>;
};

export type UkFdprincipleSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  fdPrinciple?: InputMaybe<SortOrder>;
};

export type UkLocationIcon = Document & {
  __typename?: 'UkLocationIcon';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<Image>;
  location?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UkLocationIconFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  image?: InputMaybe<ImageFilter>;
  location?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type UkLocationIconSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  location?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type UkMenu = Document & {
  __typename?: 'UkMenu';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  menuItems?: Maybe<Array<Maybe<UkMenuItem>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UkMenuFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  name?: InputMaybe<StringFilter>;
};

export type UkMenuItem = {
  __typename?: 'UkMenuItem';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  newWindow?: Maybe<Scalars['Boolean']['output']>;
};

export type UkMenuItemFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  link?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  newWindow?: InputMaybe<BooleanFilter>;
};

export type UkMenuItemSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  newWindow?: InputMaybe<SortOrder>;
};

export type UkMenuSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type UkMetric = {
  __typename?: 'UkMetric';
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  metricName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UkMetricFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  image?: InputMaybe<ImageFilter>;
  metricName?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UkMetricSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  metricName?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type UkPage = Document & {
  __typename?: 'UkPage';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  pageSections?: Maybe<Array<Maybe<UkPageSection>>>;
  pageType?: Maybe<Scalars['String']['output']>;
};

export type UkPageFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  pageType?: InputMaybe<StringFilter>;
};

export type UkPageSection = Document & {
  __typename?: 'UkPageSection';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  buttonLinks?: Maybe<Array<Maybe<UkButton>>>;
  employeeBenefits?: Maybe<Array<Maybe<UkEmployeeBenefit>>>;
  employeeProfiles?: Maybe<Array<Maybe<UkEmployeeProfile>>>;
  employeeTestimonials?: Maybe<Array<Maybe<UkEmployeeTestimonial>>>;
  fdPrinciples?: Maybe<Array<Maybe<UkFdprinciple>>>;
  fdProductLogos?: Maybe<Array<Maybe<UkProduct>>>;
  fdProductsOverview?: Maybe<Array<Maybe<UkProduct>>>;
  locationIcons?: Maybe<Array<Maybe<UkLocationIcon>>>;
  pageSectionDescription?: Maybe<Scalars['String']['output']>;
  pageSectionImages?: Maybe<Array<Maybe<Image>>>;
  pageSectionTitle?: Maybe<Scalars['String']['output']>;
  rolesOverview?: Maybe<Array<Maybe<UkRoleOverview>>>;
  sectionType?: Maybe<Scalars['String']['output']>;
};

export type UkPageSectionFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  pageSectionDescription?: InputMaybe<StringFilter>;
  pageSectionTitle?: InputMaybe<StringFilter>;
  sectionType?: InputMaybe<StringFilter>;
};

export type UkPageSectionSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  pageSectionDescription?: InputMaybe<SortOrder>;
  pageSectionTitle?: InputMaybe<SortOrder>;
  sectionType?: InputMaybe<SortOrder>;
};

export type UkPageSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
};

export type UkProduct = Document & {
  __typename?: 'UkProduct';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  appScreenshot?: Maybe<Image>;
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  link?: Maybe<Scalars['String']['output']>;
  metrics?: Maybe<Array<Maybe<UkMetric>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UkProductFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  appScreenshot?: InputMaybe<ImageFilter>;
  description?: InputMaybe<StringFilter>;
  image?: InputMaybe<ImageFilter>;
  link?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type UkProductSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  appScreenshot?: InputMaybe<ImageSorting>;
  description?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  link?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type UkRoleOverview = Document & {
  __typename?: 'UkRoleOverview';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  linkToRolePage?: Maybe<LinkToRolePage>;
  roleName?: Maybe<Scalars['String']['output']>;
  uk_careerProgression?: Maybe<Array<Maybe<UkCareerProgressionLevel>>>;
};

export type UkRoleOverviewFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  image?: InputMaybe<ImageFilter>;
  linkToRolePage?: InputMaybe<LinkToRolePageFilter>;
  roleName?: InputMaybe<StringFilter>;
};

export type UkRoleOverviewSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  linkToRolePage?: InputMaybe<LinkToRolePageSorting>;
  roleName?: InputMaybe<SortOrder>;
};

export type GetAllPrMenuQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPrMenuQuery = {
  __typename?: 'Query';
  allPrMenu: Array<{
    __typename?: 'PrMenu';
    name?: string | null;
    menuItems?: Array<{
      __typename?: 'PrMenuItem';
      name?: string | null;
      link?: string | null;
      submenu?: Array<{
        __typename?: 'PrMenuItem';
        name?: string | null;
        link?: string | null;
        newWindow?: boolean | null;
      } | null> | null;
    } | null> | null;
  }>;
};

export type GetPageFdtvTalentQueryVariables = Exact<{ [key: string]: never }>;

export type GetPageFdtvTalentQuery = {
  __typename?: 'Query';
  allPrPage: Array<{
    __typename?: 'PrPage';
    banner?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
    fdtvTalent?: Array<{
      __typename?: 'PrTalent';
      _id?: string | null;
      name?: string | null;
      slug?: { __typename?: 'Slug'; current?: string | null } | null;
      image?: {
        __typename?: 'Image';
        asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
      } | null;
    } | null> | null;
  }>;
};

export type GetPageHomepageQueryVariables = Exact<{ [key: string]: never }>;

export type GetPageHomepageQuery = {
  __typename?: 'Query';
  allPrPage: Array<{
    __typename?: 'PrPage';
    banner?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
  }>;
};

export type GetPageLeadershipQueryVariables = Exact<{ [key: string]: never }>;

export type GetPageLeadershipQuery = {
  __typename?: 'Query';
  allPrPage: Array<{
    __typename?: 'PrPage';
    banner?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
    leadership?: Array<{
      __typename?: 'PrLeader';
      _id?: string | null;
      name?: string | null;
      position?: string | null;
      slug?: { __typename?: 'Slug'; current?: string | null } | null;
      image?: {
        __typename?: 'Image';
        asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
      } | null;
    } | null> | null;
  }>;
};

export type GetPageMediaContactsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPageMediaContactsQuery = {
  __typename?: 'Query';
  allPrPage: Array<{
    __typename?: 'PrPage';
    banner?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
    contacts?: Array<{
      __typename?: 'PrContact';
      name?: string | null;
      email?: string | null;
    } | null> | null;
  }>;
};

export type GetPagePartnersQueryVariables = Exact<{ [key: string]: never }>;

export type GetPagePartnersQuery = {
  __typename?: 'Query';
  allPrPage: Array<{
    __typename?: 'PrPage';
    banner?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
    pageSections?: Array<{
      __typename?: 'PrPageSection';
      sectionTitle?: string | null;
      partnerCarousel?: Array<{
        __typename?: 'PrPartner';
        name?: string | null;
        image?: {
          __typename?: 'Image';
          asset?: {
            __typename?: 'SanityImageAsset';
            url?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  }>;
};

export type GetPagePressKitQueryVariables = Exact<{ [key: string]: never }>;

export type GetPagePressKitQuery = {
  __typename?: 'Query';
  allPrPage: Array<{
    __typename?: 'PrPage';
    banner?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
    logos?: Array<{
      __typename?: 'PrLogoItem';
      fileName?: string | null;
      submenu?: Array<{
        __typename?: 'PrLogoItem';
        fileName?: string | null;
        logo?: {
          __typename?: 'Image';
          asset?: {
            __typename?: 'SanityImageAsset';
            url?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  }>;
};

export type GetPageProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPageProductsQuery = {
  __typename?: 'Query';
  allPrPage: Array<{
    __typename?: 'PrPage';
    bodyRaw?: any | null;
    banner?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
    reel?: {
      __typename?: 'File';
      asset?: { __typename?: 'SanityFileAsset'; url?: string | null } | null;
    } | null;
    products?: Array<{
      __typename?: 'PrProduct';
      name?: string | null;
      url?: string | null;
      image?: {
        __typename?: 'Image';
        asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
      } | null;
    } | null> | null;
  }>;
};

export type GetPrLeaderBySlugQueryVariables = Exact<{
  where?: InputMaybe<PrLeaderFilter>;
}>;

export type GetPrLeaderBySlugQuery = {
  __typename?: 'Query';
  allPrLeader: Array<{
    __typename?: 'PrLeader';
    name?: string | null;
    position?: string | null;
    biographyRaw?: any | null;
    image?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
  }>;
};

export type GetPrReleaseBySlugQueryVariables = Exact<{
  where?: InputMaybe<PrReleaseFilter>;
}>;

export type GetPrReleaseBySlugQuery = {
  __typename?: 'Query';
  allPrRelease: Array<{
    __typename?: 'PrRelease';
    _updatedAt?: any | null;
    datePublished?: any | null;
    title?: string | null;
    subheading?: string | null;
    pullQuote?: string | null;
    bodyRaw?: any | null;
    slug?: { __typename?: 'Slug'; current?: string | null } | null;
    image?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
  }>;
};

export type GetPrTalentBySlugQueryVariables = Exact<{
  where?: InputMaybe<PrTalentFilter>;
}>;

export type GetPrTalentBySlugQuery = {
  __typename?: 'Query';
  allPrTalent: Array<{
    __typename?: 'PrTalent';
    name?: string | null;
    biographyRaw?: any | null;
    xLink?: string | null;
    igLink?: string | null;
    ttLink?: string | null;
    image?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
  }>;
};

export type SearchAllPrReleaseQueryVariables = Exact<{
  where?: InputMaybe<PrReleaseFilter>;
  sort?: InputMaybe<Array<PrReleaseSorting> | PrReleaseSorting>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SearchAllPrReleaseQuery = {
  __typename?: 'Query';
  allPrRelease: Array<{
    __typename?: 'PrRelease';
    _id?: string | null;
    datePublished?: any | null;
    subheading?: string | null;
    title?: string | null;
    slug?: { __typename?: 'Slug'; current?: string | null } | null;
    image?: {
      __typename?: 'Image';
      asset?: { __typename?: 'SanityImageAsset'; url?: string | null } | null;
    } | null;
  }>;
};

export const GetAllPrMenuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllPrMenu' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrMenu' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'ObjectValue', fields: [] },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'ListValue', values: [] },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '100' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'IntValue', value: '0' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'menuItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submenu' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'newWindow' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllPrMenuQuery, GetAllPrMenuQueryVariables>;
export const GetPageFdtvTalentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPageFdtvTalent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'fdtvTalent',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'banner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fdtvTalent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'asset' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPageFdtvTalentQuery,
  GetPageFdtvTalentQueryVariables
>;
export const GetPageHomepageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPageHomepage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'homepage',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'banner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPageHomepageQuery,
  GetPageHomepageQueryVariables
>;
export const GetPageLeadershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPageLeadership' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'leadership',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'banner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadership' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'position' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'asset' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPageLeadershipQuery,
  GetPageLeadershipQueryVariables
>;
export const GetPageMediaContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPageMediaContacts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'media-contacts',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'banner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPageMediaContactsQuery,
  GetPageMediaContactsQueryVariables
>;
export const GetPagePartnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPagePartners' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'partners',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'banner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageSections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sectionTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'partnerCarousel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'asset' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPagePartnersQuery,
  GetPagePartnersQueryVariables
>;
export const GetPagePressKitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPagePressKit' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'press-kit',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'banner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submenu' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fileName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'asset' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPagePressKitQuery,
  GetPagePressKitQueryVariables
>;
export const GetPageProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPageProducts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_draft' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'products',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'banner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'bodyRaw' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'asset' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPageProductsQuery,
  GetPageProductsQueryVariables
>;
export const GetPrLeaderBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPrLeaderBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PrLeaderFilter' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrLeader' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'biographyRaw' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrLeaderBySlugQuery,
  GetPrLeaderBySlugQueryVariables
>;
export const GetPrReleaseBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPrReleaseBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PrReleaseFilter' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrRelease' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datePublished' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subheading' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pullQuote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bodyRaw' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrReleaseBySlugQuery,
  GetPrReleaseBySlugQueryVariables
>;
export const GetPrTalentBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPrTalentBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PrTalentFilter' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrTalent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'biographyRaw' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'xLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'igLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttLink' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrTalentBySlugQuery,
  GetPrTalentBySlugQueryVariables
>;
export const SearchAllPrReleaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchAllPrRelease' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PrReleaseFilter' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PrReleaseSorting' },
              },
            },
          },
          defaultValue: {
            kind: 'ListValue',
            values: [
              {
                kind: 'ObjectValue',
                fields: [
                  {
                    kind: 'ObjectField',
                    name: { kind: 'Name', value: '_updatedAt' },
                    value: { kind: 'EnumValue', value: 'DESC' },
                  },
                ],
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPrRelease' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datePublished' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subheading' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchAllPrReleaseQuery,
  SearchAllPrReleaseQueryVariables
>;
