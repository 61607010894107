import { graphql } from '../gql';

const getPageProducts = graphql(`
  query GetPageProducts {
    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "products" } }) {
      banner {
        asset {
          url
        }
      }
      reel {
        asset {
          url
        }
      }
      bodyRaw
      products {
        name
        url
        image {
          asset {
            url
          }
        }
      }
    }
  }
`);

export default getPageProducts;
