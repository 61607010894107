import { graphql } from '../gql';

const getPagePressKit = graphql(`
  query GetPagePressKit {
    allPrPage(
      where: { _: { is_draft: false }, pageType: { eq: "press-kit" } }
    ) {
      banner {
        asset {
          url
        }
      }
      logos {
        fileName
        submenu {
          fileName
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  }
`);

export default getPagePressKit;
