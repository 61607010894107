import { graphql } from '../gql';

const searchAllReleases = graphql(`
  query SearchAllPrRelease(
    $where: PrReleaseFilter = {}
    $sort: [PrReleaseSorting!] = [{ _updatedAt: DESC }]
    $limit: Int = 10
    $offset: Int = 0
  ) {
    allPrRelease(where: $where, sort: $sort, limit: $limit, offset: $offset) {
      _id
      datePublished
      subheading
      title
      slug {
        current
      }
      image {
        asset {
          url
        }
      }
    }
  }
`);

export default searchAllReleases;
