import { graphql } from '../gql';

const getPageHomepage = graphql(`
  query GetPageHomepage {
    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "homepage" } }) {
      banner {
        asset {
          url
        }
      }
    }
  }
`);

export default getPageHomepage;
