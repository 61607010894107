import { graphql } from '../gql';

const getPageFdtvTalent = graphql(`
  query GetPageFdtvTalent {
    allPrPage(
      where: { _: { is_draft: false }, pageType: { eq: "fdtvTalent" } }
    ) {
      banner {
        asset {
          url
        }
      }
      fdtvTalent {
        _id
        name
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
      }
    }
  }
`);

export default getPageFdtvTalent;
