import { graphql } from '../gql';

const getPrReleaseBySlug = graphql(`
  query GetPrReleaseBySlug($where: PrReleaseFilter = {}) {
    allPrRelease(where: $where) {
      _updatedAt
      datePublished
      title
      slug {
        current
      }
      subheading
      pullQuote
      bodyRaw
      image {
        asset {
          url
        }
      }
    }
  }
`);

export default getPrReleaseBySlug;
