import { graphql } from '../gql';

const getPageMediaContacts = graphql(`
  query GetPageMediaContacts {
    allPrPage(
      where: { _: { is_draft: false }, pageType: { eq: "media-contacts" } }
    ) {
      banner {
        asset {
          url
        }
      }
      contacts {
        name
        email
      }
    }
  }
`);

export default getPageMediaContacts;
