import { request } from 'graphql-request';
import getPageMediaContactsQuery from '@/graphql/queries/getPageMediaContacts';
import getPageLeadershipQuery from '@/graphql/queries/getPageLeadership';
import getPageProductsQuery from '@/graphql/queries/getPageProducts';
import getPageFdtvTalentQuery from '@/graphql/queries/getPageFdtvTalent';
import getPagePartnersQuery from '@/graphql/queries/getPagePartners';
import getPagePressKitQuery from '@/graphql/queries/getPagePressKit';
import getPageHomepageQuery from '@/graphql/queries/getPageHomepage';
import getAllPrMenuQuery from '@/graphql/queries/getAllPrMenu';
import getPrReleaseBySlugQuery from '@/graphql/queries/getPrReleaseBySlug';
import getPrLeadershipBySlugQuery from '@/graphql/queries/getPrLeadershipBySlug';
import getPrTalentBySlugQuery from '@/graphql/queries/getPrTalentBySlug';
import searchAllPrReleaseQuery from '@/graphql/queries/searchAllPrReleases';
import { GetReleaseBySlugArgs } from '@/types/release';
import {
  SearchAllPrReleaseQueryVariables,
  SortOrder,
} from '@/graphql/gql/graphql';

export const GRAPHQL_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || 'http://localhost:8080/graphql';

export const getMenus = () => {
  return request(GRAPHQL_ENDPOINT, getAllPrMenuQuery, {});
};

export const getPageProducts = () => {
  return request(GRAPHQL_ENDPOINT, getPageProductsQuery, {});
};

export const getPageLeadership = () => {
  return request(GRAPHQL_ENDPOINT, getPageLeadershipQuery, {});
};

export const getPageMediaContacts = () => {
  return request(GRAPHQL_ENDPOINT, getPageMediaContactsQuery, {});
};

export const getPageFdtvTalent = () => {
  return request(GRAPHQL_ENDPOINT, getPageFdtvTalentQuery, {});
};

export const getPagePartners = () => {
  return request(GRAPHQL_ENDPOINT, getPagePartnersQuery, {});
};

export const getPagePressKit = () => {
  return request(GRAPHQL_ENDPOINT, getPagePressKitQuery, {});
};

export const getPageHomepage = () => {
  return request(GRAPHQL_ENDPOINT, getPageHomepageQuery, {});
};

export const getReleaseBySlug = ({ slug, isDraft }: GetReleaseBySlugArgs) => {
  const publishedFilter = {
    is_draft: false,
  };

  return request(GRAPHQL_ENDPOINT, getPrReleaseBySlugQuery, {
    where: {
      _: isDraft ? {} : publishedFilter,
      slug: {
        current: {
          eq: slug,
        },
      },
    },
  });
};

export const getLeaderBySlug = (slug: string) => {
  return request(GRAPHQL_ENDPOINT, getPrLeadershipBySlugQuery, {
    where: {
      slug: {
        current: {
          eq: slug,
        },
      },
    },
  });
};

export const getTalentBySlug = (slug: string) => {
  return request(GRAPHQL_ENDPOINT, getPrTalentBySlugQuery, {
    where: {
      slug: {
        current: {
          eq: slug,
        },
      },
    },
  });
};

export const searchReleases = ({
  where = {},
  sort = [{ datePublished: SortOrder.Desc }],
  limit = 1000,
  offset = 0,
}: SearchAllPrReleaseQueryVariables) => {
  return request(GRAPHQL_ENDPOINT, searchAllPrReleaseQuery, {
    where,
    sort,
    limit,
    offset,
  });
};
