import { graphql } from '../gql';

const getPrTalentBySlug = graphql(`
  query GetPrTalentBySlug($where: PrTalentFilter = {}) {
    allPrTalent(where: $where) {
      name
      biographyRaw
      xLink
      igLink
      ttLink
      image {
        asset {
          url
        }
      }
    }
  }
`);

export default getPrTalentBySlug;
