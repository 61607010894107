import { graphql } from '../gql';

const getPagePartners = graphql(`
  query GetPagePartners {
    allPrPage(where: { _: { is_draft: false }, pageType: { eq: "partners" } }) {
      banner {
        asset {
          url
        }
      }
      pageSections {
        sectionTitle
        partnerCarousel {
          name
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`);

export default getPagePartners;
