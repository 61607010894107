import { graphql } from '../gql';

const getAllPrMenu = graphql(`
  query GetAllPrMenu {
    allPrMenu(where: {}, sort: [], limit: 100, offset: 0) {
      name
      menuItems {
        name
        link
        submenu {
          name
          link
          newWindow
        }
      }
    }
  }
`);

export default getAllPrMenu;
