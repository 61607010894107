import { graphql } from '../gql';

const getPrLeaderBySlug = graphql(`
  query GetPrLeaderBySlug($where: PrLeaderFilter = {}) {
    allPrLeader(where: $where) {
      name
      position
      biographyRaw
      image {
        asset {
          url
        }
      }
    }
  }
`);

export default getPrLeaderBySlug;
